<template>
  <v-app>
    <v-main id="main" :style="imageBg">
      <v-container id="v-app" fill-height fluid>
        <v-row align="center" justify="center">
          <v-col cols="2" class="hidden-sm-and-down">
            <v-icon class="arrow_icon" @click="previousRoute">arrow_left</v-icon>
          </v-col>

          <v-col
            cols="12"
            sm="8"
            v-touch="{
              left: () => nextRoute(),
              right: () => previousRoute()
            }"
          >
            <v-list-item>
              <v-avatar>
                <v-img :src="require('@/assets/images/avatar.png')" class="my-3" contain width="50" />
              </v-avatar>
              <v-list-item-content class="text-right">
                <p id="title" class="mb-0">|MICROWD.</p>
                <p id="subtitle" class="mb-0">Confiar es mejor que no confiar</p>
              </v-list-item-content>
            </v-list-item>
            <Navigator v-if="$route.name != 'Home' && $route.name != 'Final'" class="mt-5 mb-10" />
            <router-view @setImageBg="setImageBg" />
          </v-col>

          <v-col cols="2" class="hidden-sm-and-down">
            <v-icon class="arrow_icon" @click="nextRoute">arrow_right</v-icon>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navigator from "@/components/Navigator.vue";

export default {
  name: "App",
  components: { Navigator },
  data() {
    return {
      imageBg: {}
    };
  },
  methods: {
    nextRoute() {
      const routes = this.$router.options.routes;
      const index = routes.findIndex((route) => route.name === this.$route.name);

      if (routes[index + 1]) {
        this.$router.push(routes[index + 1].path);
      }
    },
    previousRoute() {
      const routes = this.$router.options.routes;
      const index = routes.findIndex((route) => route.name === this.$route.name);

      if (routes[index - 1]) {
        this.$router.push(routes[index - 1].path);
      }
    },
    setImageBg(value) {
      this.imageBg = value;
    }
  }
};
</script>

<style scoped>
html,
body {
  font-family: "Montserrat", sans-serif;
}

#v-app {
  max-width: 600px;
}

#title {
  font-size: 15px;
  font-weight: 700;
}

#subtitle {
  font-size: 12px;
}

#main {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.theme--dark.v-application {
  background: #232323;
}

.arrow_icon {
  font-size: 50px !important;
}
</style>
