import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue")
  },
  // {
  //   path: "/best_practices",
  //   name: "BestPractices",
  //   component: () => import("../views/BestPractices.vue")
  // },
  {
    path: "/por_que_mujeres",
    name: "WhyWomen",
    component: () => import("../views/WhyWomen.vue")
  },
  {
    path: "/apostar_por_empresarias",
    name: "BetOnBusinesswomen",
    component: () => import("../views/BetOnBusinesswomen.vue")
  },
  {
    path: "/por_que_estos_porcentajes",
    name: "WhyThesePercentages",
    component: () => import("../views/WhyThesePercentages.vue")
  },
  {
    path: "/no_penalizamos_mora",
    name: "NotPenalizeTheDelay",
    component: () => import("../views/NotPenalizeTheDelay.vue")
  },
  {
    path: "/no_pedimos_aval",
    name: "DontAskForEndorsement",
    component: () => import("../views/DontAskForEndorsement.vue")
  },
  {
    path: "/nuestros_prestamos",
    name: "OurLoansTooBig",
    component: () => import("../views/OurLoansTooBig.vue")
  },
  {
    path: "/apostamos_por_baja_rotacion",
    name: "BetOnLowRotationOfTechnicians",
    component: () => import("../views/BetOnLowRotationOfTechnicians.vue")
  },
  {
    path: "/apostamos_por_tecnologia",
    name: "WhyWeWorkOnTechnology",
    component: () => import("../views/WhyWeWorkOnTechnology.vue")
  },
  {
    path: "/por_que_blockchain",
    name: "WhyNowBlockchain",
    component: () => import("../views/WhyNowBlockchain.vue")
  },
  {
    path: "/vendemos_excelencia",
    name: "WeSellExcellence",
    component: () => import("../views/WeSellExcellence.vue")
  },
  {
    path: "/buscamos_a_las_mejores",
    name: "WeLookForTheBest",
    component: () => import("../views/WeLookForTheBest.vue")
  },
  {
    path: "/confiar_es_mejor_que_no_confiar",
    name: "Final",
    component: () => import("../views/Final.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
