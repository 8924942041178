<template>
  <v-row class="px-8">
    <v-col v-for="(route, i) in routes" :key="i" class="pa-0 mr-1">
      <div class="page" :class="indexActualRoute >= i ? 'primary' : ''" @click="$router.push(route.path)" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Navigator",
  data() {
    return {
      routes: [...this.$router.options.routes]
    };
  },
  computed: {
    indexActualRoute() {
      const indexActual = this.routes.findIndex((route) => route.name === this.$route.name);
      return indexActual;
    }
  }
};
</script>

<style scoped>
.page {
  height: 10px;
  background-color: white;
  border-radius: 25px;
  cursor: pointer;
}
</style>
